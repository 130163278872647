import React from "react";
import "./App.css";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Container from 'react-bootstrap/Container';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "lv",
    detection: {
      checkWhitelist: true,
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: "moboLang",
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}.json",
    },
  });

function App() {
  const { t } = useTranslation();

  return (
    <Container>
    <div className="App">
      <header className="App-header">
        <h1>{t('title')}</h1>
      </header>
      <div>
        <p> {t('intro.point1')}</p>
        <p> {t('rules.1.1')}</p>
        <p> {t('rules.1.2')}</p>
        <b><p> {t('rules.2')}</p></b>
        <p> {t('rules.21')}</p>
        <p> {t('rules.22')}</p>
        <p> {t('rules.23')}</p>
        <b><p> {t('rules.3')}</p></b>
        <p> {t('rules.31')}</p>
        <p> {t('rules.32')}</p>
        <b><p> {t('rules.4')}</p></b>
        <p> {t('rules.41')}</p>
        <p> {t('rules.42')}</p>
        <p> {t('rules.43')}</p>
        <b><p> {t('rules.5')}</p></b>
        <p> {t('rules.51')}</p>
        <p> {t('rules.52')}</p>
        <b><p> {t('rules.6')}</p></b>
        <p> {t('rules.61')}</p>
        <p> {t('rules.62')}</p>
        <p> {t('rules.7')}</p>
        <p> {t('rules.71')}</p>
        <p> {t('rules.72')}</p>
        <b><p> {t('rules.8')}</p></b>
        <p> {t('rules.81')}</p>
        <b><p> {t('rules.9')}</p></b>
        <p> {t('rules.91')}</p>
        <b><p> {t('rules.10')}</p></b>
        <p> {t('rules.101')}</p>
        <p> {t('rules.date')}</p>
        <b><p> {t('rules.info')}</p></b>
      </div>
    </div>
    </Container>
  );
}

export default App;
